export { default as Dispatcher } from './Dispatcher'
export { default as Deferred } from './Deferred'

/* eslint-disable no-console */
export const errorHandling =
  (fn, handleError) =>
  (...args) => {
    try {
      const res = fn(...args)

      return res?.catch instanceof Function
        ? res.catch(e => handleError(e, ...args))
        : res
    } catch (e) {
      handleError(e, ...args)
    }
  }

export const createErrorReporting = logger => (fn, PresetError) =>
  errorHandling(fn, e => logger.log(new PresetError({ cause: e })))

export const createBreadcrumbReporting = logger => (fn, PresetBreadcrumb) =>
  errorHandling(
    (...args) => {
      const result = fn(...args)

      logger.log(new PresetBreadcrumb({ data: { args, result } }))

      return result
    },
    e => {
      logger.log(
        new PresetBreadcrumb({ data: { exception: e }, level: 'error' }),
      )
      throw e
    },
  )

export class Thenable {
  constructor(entity) {
    return entity?.then ? entity : { then: cb => cb(entity) }
  }
}

export class BoundDecorator {
  create(deroratorFn) {
    return (...decoratorArgs) =>
      (...calleArgs) =>
        this.#target
          ? deroratorFn.call(this.#target, ...decoratorArgs)(...calleArgs)
          : console.error(
              `BoundDecorator Error: isn't bound. You must bind to some object before calling decorated methods`,
            )
  }

  bind(target) {
    this.#target = target
  }

  #target
}

export class ExtendedMap extends Map {
  getOrDefault(key, def) {
    return this.has(key) ? this.get(key) : this.set(key, def).get(key)
  }
}

// you have similar in stage 1 proposal and implemented in core-js. but the lib is too big
export class ExtendedSet extends Set {
  reduce(cb, acc) {
    return Array.from(this).reduce(
      (accum, val, i) => cb(accum, val, i, this),
      acc,
    )
  }
}
