import { ExtendedMap, ExtendedSet } from '.'

export default class Dispatcher {
  constructor(context) {
    this.#context = context
  }

  dispatch(event, ...args) {
    if (this.#eventToListener.has(event)) {
      this.#eventToListener
        .get(event)
        .forEach(listener => listener(this.#context, ...args))
    }
  }

  subscribe(event, listener) {
    if (event.constructor === Object) {
      return Object.entries(event).reduce(
        (acc, [eventType, listener]) => [
          ...acc,
          this.subscribe(eventType, listener),
        ],
        [],
      )
    }

    this.#eventToListener.getOrDefault(event, new ExtendedSet()).add(listener)

    return () => this.unsubscribe(event, listener)
  }

  unsubscribe(event, listener) {
    return (
      this.#eventToListener.has(event) &&
      this.#eventToListener.get(event).delete(listener)
    )
  }

  #context
  #eventToListener = new ExtendedMap()
}
